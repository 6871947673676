<template>
    <section v-if="errored">
      <Errored></Errored>
    </section>
    <section v-else>
      <div class="list justify-center mt-0 pa-0">
        <section>
          <v-spacer></v-spacer>
            <v-row>       
              <v-col cols="8" sm="8" md="8">
                <v-select
                  v-model="selectedCameras"
                  :items="cameras"
                  v-bind:label="$t('Cameras')"
                  class="camera_select"
                  @change="onQueryParamsChanged"
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="toggle"
                    >
                      <v-list-item-action>
                        <v-icon :color="selectedCameras.length > 0 ? 'indigo darken-4' : ''">
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t("Select All") }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="4" sm="4" md="4" align="start">
                  <v-btn min-width="80px" small color="success" @click="getRawEvents"> {{ $t("Create CSV") }} </v-btn>
                  <v-btn min-width="80px" small color="grey lighten-1" @click="clearFilter"> {{ $t("Clear") }} </v-btn>
              </v-col>
            </v-row>
            <v-row align-content="start">
              <v-col cols="12" sm="2" md="2">
                <v-radio-group v-model="radios" mandatory @change="onQueryParamsChanged">
                  <v-radio v-bind:label="$t('Time Interval')" value="interval"></v-radio>
                  <v-radio v-bind:label="$t('Custom')" value="custom"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col v-if="radios ==='custom'" cols="3" sm="3" md="2">
                <v-menu
                  v-model="menu_start"
                  :close-on-content-click="false"                
                  transition="scale-transition"
                  offset-y
                  min-width="220px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_start"
                      v-bind:label="$t('Start')"
                      prepend-icon="mdi-calendar"
                      dense hide-details
                      v-bind="attrs"
                      v-on="on"
                      class="shrink"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date_start"
                    @input="menu_start = false"
                    width="220px"
                    no-title
                    info
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col v-if="radios ==='custom'" cols="2" sm="2" md="2">
                <v-select
                  v-model="hour_start"
                  :items="hours"
                  class="hour_select"
                >
                </v-select>
              </v-col>
              <v-col v-if="radios ==='custom'" cols="3" sm="3" md="2">
                <v-menu
                  v-model="menu_end"
                  :close-on-content-click="false"                
                  transition="scale-transition"
                  offset-y
                  min-width="220px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_end"
                      v-bind:label="$t('End')"
                      prepend-icon="mdi-calendar"
                      dense hide-details
                      v-bind="attrs"
                      v-on="on"
                      class="shrink"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date_end"
                    @input="menu_end = false"
                    width="220px"
                    no-title
                    info
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col v-if="radios ==='custom'" cols="2" sm="2" md="2">
                <v-select
                  v-model="hour_end"
                  :items="hours"
                  class="hour_select"
                >
                </v-select>
              </v-col>
              <v-col v-if="radios ==='interval'" cols="4" sm="4" md="4">
                <v-select
                  v-model="interval"
                  :items ="intervals"
                  class="interval_select"
                >
                  <template v-slot:selection="data">
                    {{ $t(data.item) }}
                  </template>
                  <template v-slot:item="data">
                    {{ $t(data.item) }}
                  </template>              
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-text-field hide-details="true" v-if="loading" color="success" loading disabled></v-text-field>
              <v-divider v-else></v-divider>
            </v-row>
            <RecentQueriesList :items = queries :cameras = cameras></RecentQueriesList>
            <v-alert dense prominent :type="alert_type" :value="alert" transition="scale-transition" icon="mdi-map-marker-alert">
                {{ $t(alert_message) }}
            </v-alert>
        </section>
      </div>
    </section>
  </template>
  
  <script>
  import moment from 'moment';
  import Errored from './Errored.vue';
  import RecentQueriesList from './RecentQueriesList.vue';
  import API from '../api';

  const ATHENA_RECENT_QUERIES_UPDATE_TIME = 20000; // every 20 seconds

  export default {
    name: 'RawEvents',
    components: { Errored, RecentQueriesList },
    data: () => ({
      hours : [
        '00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00',
        '12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00'
      ],
      hour_start: moment().subtract(1,'hours').format('HH:00'),
      hour_end: moment().format('HH:00'),
      date_start: moment().subtract(1,'hours').format('YYYY-MM-DD'),
      date_end: moment().format('YYYY-MM-DD'),
      menu_start: false,
      menu_end: false,
      intervals : ['Last Hour', 'Last Day', 'Last Week', 'Last Month', 'Last Year'],
      interval: 'Last Hour',    
      radios: null,
      headers: [     
       // { text: 'Id',  align: 'center', value: 'camera_id', sortable: false, filterable: false, width: '35px'},
        { text: 'Time', align: 'start', value: 'local_time'},
        { text: 'Total events', align: 'end' , value: 'stat_car_count'},
        { text: 'Failed events', align: 'end' , value: 'stat_fail_lpr_count'},
      ],
      cameras: [],
      selectedId: 0,
      selectedCameras: [],
      search: '',
      alert_message: '',
      loading: true,
      errored: false,
      alert: false,
      alert_type: 'warning',
      error: '',
      queries: [],
      intervalAPIcall: null
    }),
  
    created () {
      this.initialize()
    },
    mounted() {
      // use previous camera selection...
      if (window.localStorage.getItem('cameras')) {
        try {
          this.selectedCameras = JSON.parse(window.localStorage.getItem('cameras'));
        } catch(e) {
          window.localStorage.removeItem('cameras');
        }
      }
      // set an interval to get Athena Recent queries
      this.intervalAPIcall = setInterval(() => {
        this.makeAthenaReportQueryList();
      },  ATHENA_RECENT_QUERIES_UPDATE_TIME);
    },
    beforeDestroy() {
      if (this.intervalAPIcall) {
        clearInterval(this.intervalAPIcall);
      }
    },
    computed: {
      selectAllCamera () {
        return this.selectedCameras.length === this.cameras.length
      },
      selectSomeCamera () {
        return this.selectedCameras.length > 0 && !this.selectAll
      },
      icon () {
        if (this.selectAllCamera) return 'mdi-close-box'
        if (this.selectSomeCamera) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      request_str() {
        let str = '';
        let fromFormatedTime;
        let toFormatedTime;
        let userName = API.appScope.theCurrUserName;
        
        if (this.radios === 'custom') {
          fromFormatedTime = moment(this.date_start +'T' +this.hour_start).format()
          toFormatedTime   = moment(this.date_end +'T' +this.hour_end).format()
        }
        else {
          // time interval
          switch (this.interval) {
            case 'Last Hour':
              fromFormatedTime = moment().subtract(1, 'hours').format()
              break;
            case 'Last Day':
              fromFormatedTime = moment().subtract(1, 'days').format()
              break;
            case 'Last Week':
              fromFormatedTime = moment().subtract(7, 'days').format()
              break;
            case 'Last Month':
              fromFormatedTime = moment().subtract(1, 'months').format()
              break;
            case 'Last Year':
              fromFormatedTime = moment().subtract(1, 'years').format()
              break;
            default:
              fromFormatedTime = moment().format()
          }
          toFormatedTime   = moment().format()
        }
        
        const quotedIds = this.selectedCameras.map(id => `'${id}'`);
        str += "?cameraIdsStr=" + encodeURIComponent(quotedIds.join(','))
        str += "&fromStatTime=" + encodeURIComponent(fromFormatedTime)
        str += "&toStatTime="   + encodeURIComponent(toFormatedTime)
        str += "&userName="     + encodeURIComponent(userName)
        return str
      },
    },
  
    methods: {   
      initialize () {
        API.getCameras()
        .then((response) => {
          if (response.data.isOk) {
            this.cameras = response.data.data.map(camera => {
              return {...camera, text:camera.camera_name, value:camera.camera_id}
            });
            
            this.makeAthenaReportQueryList()
          }
        })
        .catch(error => {
          this.errored = !!error
        })
        .finally(() => (this.loading = false))
      },
  
      toggle () {
        this.alert = false
        this.$nextTick(() => {
          if (this.selectAllCamera) {
            this.selectedCameras = []
          } else {
            this.selectedCameras = this.cameras.map(camera => {
              return camera.value
            });
          }
        })
      },
      saveSelectedCameras() {
        const parsed = JSON.stringify(this.selectedCameras)
        window.localStorage.setItem('cameras', parsed)
      },
      removeSelectedCameras() {
        window.localStorage.removeItem('cameras')
      },

      onQueryParamsChanged() {
        this.alert = false
      },

      clearFilter () {
        this.selectedCameras = []
        this.hour_start = moment().subtract(1,'hours').format('HH:00')
        this.hour_end   = moment().format('HH:00')
        this.date_start = moment().subtract(1,'hours').format('YYYY-MM-DD')
        this.date_end   = moment().format('YYYY-MM-DD')
        this.removeSelectedCameras()
      },   
  
      getRawEvents() {
        if (this.selectedCameras.length == 0) {
          this.alert_message = "No camera selected"
          this.alert_type = 'warning'
          this.alert = true
          return
        }
        this.saveSelectedCameras()
  
        this.loading = true
 
        API.makeRawEventsData(this.request_str)
        .then((response) => {
          if (response.data.isOk) {
            // call this new RecentQueriesList 
            this.makeAthenaReportQueryList()
          }
        })
        .catch(error => {
          this.errored = !!error
          this.alert_type = 'error'
          this.alert_message = 'Report creation error: ' + error
          this.alert = true
        })
        .finally(() => {
          this.loading = false
        });
      },
 
      makeAthenaReportQueryList() {
        this.loading = true

        API.makeAthenaReportQueryList()
        .then((response) => {
          if (response.data.isOk) {
            this.queries = response.data.data
            this.loading = false
          }
        })
        .catch(error => {
          this.errored = !!error
          this.alert_type = 'error'
          this.alert_message = 'Athena: error getting latest requests: ' + error
          this.alert = true
        })
        .finally(() => {
          this.loading = false
        });
      },

      formatViewDate(value){
        return moment(String(value)).format('YYYY-MM-DD')
      },
      formatViewStartTime(value){
        return moment(String(value)).format('HH:mm')
      },
      formatViewEndTime(value){
        return moment(String(value)).add(1,'hours').format('HH:mm')
      },
    },
  }
  </script>
  
  <style scoped>
    ::v-deep .v-label { font-size: 1.2em; }
    ::v-deep .v-radio > .v-label {font-size: 12px; color: rgba(0, 0, 0, 0.87);}
    .v-btn.v-size--small {
      margin-left: 5px;
      margin-bottom: 5px;
      font-size: 0.7rem;
    }
    .v-text-field input { font-size: 1em; padding: 0; }
    .v-input--selection-controls { margin-top: 0; padding-top: 0;}
    .v-radio { margin-bottom: 0;}
    .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) { margin-bottom: 2px;}
    ::v-deep .v-select > .v-input__control > .v-input__slot { margin-bottom: 0px!important; }
    .hour_select::v-deep .v-select__selection.v-select__selection--comma { margin-bottom: 0; }
    .interval_select::v-deep .v-select__selection.v-select__selection--comma { margin-bottom: 0; }
    .camera_select::v-deep .v-select__selection.v-select__selection--comma { margin-bottom: 0; }
    .v-input.hour_select { min-width: 65px; }
    .v-input.camera_select { width: 100%; }
    ::v-deep .v-list-item__content { padding: 0; }
    ::v-deep .v-list-item__action { margin: 2px 6px 2px 2px!important; }
    ::v-deep .v-list-item__title { font-size: 12px; }
    ::v-deep .v-list-item--link { font-size: 12px; }
    ::v-deep .v-text-field { font-size: 12px; }
    .row.align-content-start > .col-6 { padding-right: 2px; }
    .list .v-alert {
      position: relative!important;
      font-size: 14px!important;
      width: 100%
    }
    .v-btn:not(.v-btn--round).v-size--default {
      height: 28px;
      min-width: 80px;
      padding: 0 5px;
      margin-right: 3px;
      font-size: 0.7rem;
    }
    .v-card__subtitle, .v-card__text, .v-card__title { padding: 8px; }
    ::v-deep .v-treeview-node .v-treeview-node__root {
      min-height: 28px;
      margin-top: 0;
      margin-bottom: 0;
    }
    ::v-deep .v-treeview-node__label {
      display: flex;
      align-items: center;
    }
    .v-treeview-node__label > a {
      color: #424242;
      font-size: .875em;
      padding-right: 20px;
    }
    .v-treeview-node__root .v-treeview-node__label > a { 
      width: 180px;
      overflow: hidden;
      white-space: nowrap; 
      text-overflow: ellipsis;
    }
    .v-treeview-node__children .v-treeview-node__root .v-treeview-node__label > a { 
      width: 90px;
      overflow: hidden;
      white-space: nowrap; 
      text-overflow: ellipsis;
     }
  
    ::v-deep .current {background-color: #e0e0e0;}
    .report_info {margin: 0; font-size: .875em; color: rgba(0, 0, 0, 0.38);}
    ::v-deep .v-input--is-loading.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {height: 2px!important;}
  </style>
  