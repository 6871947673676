<template>
  <v-card class="ma-1 pa-2" color="grey lighten-2">    
      <v-container>
        <v-row>
          <v-col cols="3" sm="3" md="3">
            <h5>{{ $t('Id')}}</h5>
          </v-col>
          <v-col cols="9" sm="9" md="9">
            <v-text-field readonly v-bind:value="item.camera_id" hide-details></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" sm="3" md="3">
            <h5>{{ $t('Name')}}</h5>
          </v-col>
          <v-col cols="9" sm="9" md="9">
            <v-text-field readonly v-bind:value="item.camera_name" hide-details></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" sm="3" md="3">
            <h5>{{ $t('Description')}}</h5>
          </v-col>
          <v-col cols="9" sm="9" md="9">
            <v-text-field readonly v-bind:value="item.camera_desc" hide-details></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" sm="3" md="3">
            <h5>{{ $t('Coords (lat, lng)')}}</h5>
          </v-col>
          <v-col cols="9" sm="9" md="9">
            <v-text-field readonly v-bind:value="location" hide-details></v-text-field>
          </v-col>
        </v-row>
      </v-container>
  </v-card>
</template>

<script>
import API from './../api';

export default {
    name: "CameraItem",
    data() {
        return {
          location: API.appScope.joinCoordinates(this.item.camera_lat, this.item.camera_lon)
        };
    },
    props: { 'item' : Object },
    methods: {},
 }
</script>

<style scoped>  
  .v-card {max-width: 350px}
  .v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}
.row + .row {
    margin-top: 2px;
}
.row > div.col-sm-3.col-md-3.col-3 {
    padding-bottom: 0;
    padding-left: 0!important;
}
.container {padding: 0 12px 0 16px;}
</style>
