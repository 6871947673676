<template>
  <section v-if="errored">
    <Errored></Errored>
  </section>
  <section v-else>
    <div class="list justify-center mt-10 pa-1">
      <v-text-field v-if="loading" color="success" loading disabled></v-text-field>
      <v-data-table v-else
        :headers="headers"
        :items="cameras"
        :search="search"
        :footer-props="{
            itemsPerPageText: $t('Rows per page:'), 
            pageText: $t('{0}-{1} of {2}'),
            'items-per-page-options':[30, 50, 100, -1]
          }"
        sort-by="camera_id"
        class="elevation-1 grey lighten-2"
        :single-expand="true"
        :expanded.sync="expanded"
        item-key="camera_id"
        show-expand
        mobile-breakpoint="0"
        single-select
        @click:row="rowClick"
      >
        <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
          {{ $t(header.text) }}
        </template>

        <template v-slot:top>
          <v-toolbar flat height="55px">
            <v-spacer></v-spacer>         
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              v-bind:label="$t('Search')"
              single-line
              clearable
              class="pa-1 pt-4 shrink"
            ></v-text-field>
            <v-dialog v-model="dialog" persistent hide-overlay max-width="500">
              <v-card color="grey lighten-3" class="camera_item">
                <v-toolbar dense dark>
                  <v-toolbar-title>{{ $t('Edit Camera') }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon small dark @click="dialog = false"> <v-icon small>mdi-close</v-icon> </v-btn>
                </v-toolbar>
    
                <v-form ref="form" lazy-validation :readonly="isReadOnly">                
                  <v-container>
                    <v-row>
                      <v-col cols="6" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.camera_id"
                          v-bind:label="$t('Id')"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" sm="2" md="2" v-if="isEditAllow" class="btn-edit">
                        <v-btn class="ma-0 pa-0 tooltip" color="blue darken-1" dark @click="isReadOnly = !isReadOnly">
                          <span class="tooltiptext">{{ $t('Edit') }}</span>
                          <v-icon>mdi-file-document-edit-outline</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="5" sm="5" md="5">
                        <v-text-field
                          v-model="editedItem.camera_name"
                          v-bind:label="$t('Name')"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="5" sm="5" md="5">
                        <v-text-field
                          v-bind:value="editedItem.camera_lat + ',' +editedItem.camera_lon"
                          v-bind:label="$t('Coords (lat, lng)')"
                          class="coords"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-text-field
                          v-model="editedItem.lane_num"
                          v-bind:label="$t('Lanes')"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" sm="4" md="4">
                        <v-text-field
                          v-model="editedItem.controller_id"
                          v-bind:label="$t('Controller')"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container> 
                
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success"        v-if="isEditAllow" @click="save" :disabled="isReadOnly"> {{ $t("Save") }} </v-btn>
                    <v-btn color="grey lighten-1" v-if="isEditAllow" @click="close"> {{ $t("Cancel") }} </v-btn>
                    <v-btn color="grey lighten-1" v-else @click="close"> {{ $t("Close") }} </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>  
          </v-toolbar>
          <v-row>
            <v-col class="status_filter" cols="8" sm="8" md="8">
              <span>{{ $t('Filter by status') }}</span> 
              <v-select
                v-model="selectedStatus"
                :items="statuses"
                v-on:change="filterByStatus()"
                item-text="text"
                item-value="code"
                hide-details
                single-line
                class="status_select"
              >
                <template v-slot:item="{ item }">
                  <v-icon class="pa-1" :color="item.color"> mdi-circle</v-icon> {{$t(item.text)}}
                </template>
                <template #selection="{ item }">
                  <v-avatar class="ma-1" :color="item.color" size="18">
                    <span>{{filteredCameraCount}}</span>
                  </v-avatar>
                  {{$t(item.text)}}
                </template>
              </v-select>
            </v-col>
          </v-row>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row v-if="item.status_code != null" class="exp pa-0 shrink">           
              <v-col cols="4" sm="2" md="2" class="font-weight-medium">{{ $t("Camera Status") }}</v-col>
              <v-col cols="8" sm="8" md="8">{{ item.status_message }}</v-col>
            </v-row>
            <v-row class="exp pa-0 shrink">           
              <v-col cols="4" sm="2" md="2" class="font-weight-medium">{{ $t("Camera Description") }}</v-col>
              <v-col cols="8" sm="8" md="8">{{ item.camera_desc }}</v-col>
            </v-row>
            <v-row class="exp pa-0 shrink">
              <v-col cols="4" sm="2" md="2" class="font-weight-medium">{{ $t("Controller Id") }}</v-col>
              <v-col cols="8" sm="8" md="8">{{ item.controller_id }}</v-col>
            </v-row>
          </td>
        </template>

        <template v-slot:item.actions="{ item }">   
          <v-icon class="mr-2" @click="editItem(item)" :title="$t('Details')">mdi-file-document-outline</v-icon>   
          <v-icon :class="[item.status_class]"  @click="openMap(item.camera_id)" :title="$t('Show on the map')">
            mdi-map-marker
          </v-icon>
        </template>
        <template #item.data-table-expand="{ item, expand, isExpanded }">
          <td class="text-end">
            <v-btn icon 
              @click="expand(!isExpanded)" 
              class="v-data-table__expand-icon"
              :class="{'v-data-table__expand-icon--active' : isExpanded}">
              <v-icon :title="$t('Show more')">mdi-chevron-double-down</v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
      <MapItem :visible="showMapItem" @close="showMapItem=false" :item="curr_camera"></MapItem>
    </div>
  </section>
</template>

<script>
import API from './../api';
import MapItem from './MapItem.vue';
import Errored from './Errored.vue';

export default {
  name: 'CameraList',
  components: {  MapItem, Errored },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    search: '',
    rules: {
      required: v => !!v  || 'Item is required'
    },
    headers: [
      { text: 'Id',  align: 'start', value: 'camera_id', filterable: false },
      { text: 'Name',  align: 'start', value: 'camera_name' },
      { text: 'Lat',  align: 'start', value: 'camera_lat' },
      { text: 'Lng',  align: 'start', value: 'camera_lon' },
      { text: '', align: 'end', class: "pa-0", value: 'actions', sortable: false, filterable: false },
      { text: '', value: 'data-table-expand' },
    ],
    cameras: [],
    camerasCopy: [],
    isReadOnly: true,
    editedIndex: -1,
    editedItem: {
      camera_id: '',
      camera_name: '',
      camera_desc: '',
      camera_lat: 0,
      camera_lon: 0,
      lane_num: 0,
      controller_id: 0,
    },
    curr_camera: {},
    expanded: [],
    statuses: [
      { code: -1, color: '#bdbdbd', text: 'All' },
      { code: 0,  color: '#4caf50', text: 'Online'},
      { code: 10, color: 'yellow', text: 'EventTimeout'},
      { code: 11, color: 'yellow', text: 'LowConfidence'},
      { code: 100, color: 'red', text: 'OffLine'}
    ],
    selectedStatus: -1,
    show_not_assigned: false,
    showMapItem: false,
    loading: true,
    errored: false
  }),
  created () {
    this.initialize()
  },
  mounted() {
    window.addEventListener('gotLiveCamerasData', this.onCamerasLiveUpdatedEvent);
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener('gotLiveCamerasData', this.onCamerasLiveUpdatedEvent);
    next()
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  computed: {
    isEditAllow () { 
      return (API.appScope.theCurrUserRole === API.appConst.ROLE_ADMIN) 
    },
    filteredCameraCount () {
      return this.cameras.length
    }
  },
  methods: {   
    initialize () {
      API.getCameras()
      .then((response) => {
        if (response.data.isOk) {
          this.cameras = response.data.data.map(camera => {
            let status = API.appScope.getLiveCameraStatus(camera.camera_id)
            return {...camera , status_class: status.type, status_code:status.code, status_message:status.message }
          });

          if (this.selectedStatus != -1) {
            this.cameras = this.cameras.filter(camera => camera.status_code === this.selectedStatus)  
          }

          this.camerasCopy = [...this.cameras]
        }
      })
      .catch(error => {
        console.log('ERROR:',error)
        this.errored = !!error;
      })
      .finally(() => (this.loading = false));
    },
    onCamerasLiveUpdatedEvent() {
      this.cameras.forEach(camera => {
        let status = API.appScope.getLiveCameraStatus(camera.camera_id)
          camera.status_class   = status.type
          camera.status_code    = status.code 
          camera.status_message = status.message
      });
      this.camerasCopy.forEach(camera => {
        let status = API.appScope.getLiveCameraStatus(camera.camera_id)
          camera.status_class   = status.type
          camera.status_code    = status.code 
          camera.status_message = status.message
      });
      this.filterByStatus()
    },
    filterByStatus() {
      if (this.selectedStatus != -1) {
        this.cameras = this.camerasCopy.filter(camera => camera.status_code === this.selectedStatus)
      }
      else {
        this.cameras = this.camerasCopy
      }
    },
    editItem (item) {
      this.editedIndex = this.cameras.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    close () {
      this.dialog = false
      
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
     
      this.isReadOnly = true

      this.$refs.form.resetValidation()
    },
    openMap (camera_id) {
      this.curr_camera = this.cameras.find(camera => camera.camera_id == camera_id)
      this.showMapItem = true
    },
    rowClick (item, row) {      
      row.select(true);
    },
    save () {
      const isValid = this.$refs.form.validate();
      if (!isValid) return

      API.editCamera(this.editedItem)
        .then((response) => {
          if (response.data.isOk) {
            Object.assign(this.cameras[this.editedIndex], this.editedItem)
          }
        })
        .catch(error => {
          this.errored = !!error;
        })
        .finally(() => {
          this.loading = false
          this.close()
        })
    },
  },
}
</script>

<style scoped>
.row + .row { margin-top: 2px;}
.row.exp > div {
  padding: 1px!important;
  font-size: .9em;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child { 
  overflow:hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
 }
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th { padding: 0 8px;}
.v-input--selection-controls { margin: 2px; padding-top: 0px;}
::v-deep .v-input--selection-controls .v-input__slot > .v-label {
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
}
.camera_item .v-text-field { padding-top: 0;}
.camera_item .v-btn { padding: 0 10px;}
.camera_item .v-select__slot { padding-top: 2px;}
.camera_item .btn-edit { padding-bottom: 18px; padding-right: 4px; text-align: end;}
.camera_item > .v-input__control > .v-input__slot { margin-bottom: 2px;}
::v-deep .camera_item .v-text-field__slot input { padding-bottom: 2px!important;}
.v-input--selection-controls__input { margin-right: 2px;}
.unassign_btn { margin-top: -20px;}
.v-input--checkbox {display: inline-flex;}
.unassigned_cameras { display: flex; align-items: flex-end; padding-top: 0px; padding-left: 4px;}
.v-avatar {
  border-radius: 5px; 
  width: 30px!important;
  font-size: 11px;
}
.v-avatar.deep-orange {
    background-color: orangered;
    border-color: orangered;
    top: -5px;
}
.v-avatar.ma-1 { top: 1px;}
.status_select::v-deep .v-select__selection.v-select__selection--comma { margin-bottom: 0; margin-top: 14px}
.v-input.status_select {
    width: 150px;
    max-width: 150px;
    padding-left: 8px;
  }
.status_filter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 2px;
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
} 
::v-deep .v-list-item__content { padding: 0; }
  ::v-deep .v-list-item__action { margin: 2px 6px 2px 2px!important; }
  ::v-deep .v-list-item__title { font-size: 12px; }
  ::v-deep .v-list-item--link { font-size: 12px; }
  ::v-deep .status_filter .v-text-field { font-size: 12px; }

</style>
