<template>
  <div class="justify-center mt-14 pa-0">
  <!--
    <v-tabs
      color="light-green accent-4"
      first-active
      dark
    >

      <v-tab href="#rawevents">
        {{ $t('Raw Events') }}
      </v-tab>
      <v-tab-item value="rawevents">
        <RawEvents></RawEvents>
      </v-tab-item>
    </v-tabs>        
  -->

    <RawEvents></RawEvents>

  </div>
</template>

<script>
import RawEvents from '../components/RawEvents.vue';

export default {
  name: 'Report',
  components: { RawEvents },
  data() {
    return {
      tab: null,
    };
  } 
}
</script>

<style scoped>
::v-deep .v-slide-group__content { background-color: gray;}
::v-deep .v-tabs-bar { height: 39px;}
::v-deep .v-tab {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  min-width: 200px;
  font-size: .75rem;
  font-weight: 600;
  letter-spacing: 0.07em;
  color:#424242!important;
}
::v-deep .v-icon { font-size: 20px;}
.v-tabs-slider-wrapper {left: 200px;}
.v-tab--active, .v-tab--active .v-icon { color:white!important; background-color: #a5a5a5!important;}
.theme--dark.v-tabs .v-tab--active:hover::before, .theme--dark.v-tabs .v-tab--active::before { opacity: 0;}
.theme--dark.v-tabs .v-tab:hover::before { opacity: 0;}
::v-deep .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
    display: none;
}
</style>