<template>
 <section v-if="errored">
    <Errored></Errored>
  </section>
  <section v-else>
    <div class="list justify-center mt-0 pa-1">
      <v-text-field v-if="loading" color="success" loading disabled></v-text-field>
      <v-data-table v-else
        dense
        :headers="headers"
        :items="items"
        :search="search"
        :items-per-page="15"
        sort-by="start_date_time desc"
        item-key="execution_id"
        single-select
      >
        <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
          {{ $t(header.text) }}
        </template>

        <template v-slot:top>
            <v-toolbar-title class="text-h9">{{ $t("Recent queries") }}</v-toolbar-title>
        </template>

        <template v-slot:item.status="{ item }">
            <v-chip class="status"
              :color="getStatusColor(item.status)"
              outlined
            >
                {{ item.status }}
            </v-chip>
        </template>
        <template v-slot:item.query="{ item }">
          <v-hover v-slot="{ hover }">
            <span v-if="hover && item.query.length > 100" class="query">{{  replaceCameraIdsWithString(item.query) }}</span>
            <span v-else>{{ replaceCameraIdsWithString(item.query) }}</span>
          </v-hover>
        </template>
        <template v-slot:item.execution_time="{ item }">
            {{ formatViewExecutionTime(item.execution_time) }}
        </template>
        <template v-slot:item.start_date_time="{ item }">
            {{ formatViewDate(item.start_date_time) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn v-if="item.status === 'SUCCEEDED'" class="download tooltip" @click="downloadCSV(item.report_url)" outlined small color="primary">
             <span class="tooltiptext">{{ $t('Download CSV') }}</span>
             <v-icon small color="primary" @click="copyToClipboard">mdi-tray-arrow-down</v-icon>
          </v-btn>
          &nbsp;&nbsp;
          <v-btn v-if="item.status === 'SUCCEEDED'" class="download tooltip" @click="copyToClipboard(item.report_url)" outlined small color="primary">
             <span class="tooltiptext">{{ $t('Copy url to clipboard') }}</span>
             <v-icon small color="primary" @click="copyToClipboard">mdi-content-copy</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div> 
        <v-alert class="over" dense prominent :type="alert_type" v-model="alert" transition="scale-transition" icon="mdi-map-marker-alert">
            {{ alert_message }}
        </v-alert>      
      </section>
  </template>
    
  <script>
    //import API from './../api';
    import moment from 'moment';
    import Errored from './Errored.vue';
   
    export default {
      components: { Errored },
      props: { 
        'items': Array,
        'cameras': Array
      },     
      data: () => ({
        search: '',
        headers: [
          //{ text: 'Execution ID', align: 'start', sortable: true, value: 'execution_id'},
          { text: 'User', align: 'start', sortable: true, value: 'user'},
          { text: 'Query', align: 'start', sortable: true, value: 'query', width: '500px'},
          { text: 'Start time', align: 'start', sortable: true, value: 'start_date_time'},
          { text: 'Status', align: 'start', sortable: true, value: 'status'},
          { text: 'Run time', align: 'start', sortable: true, value: 'execution_time'},
          //{ text: 'Count', align: 'start', sortable: true, value: 'output_rows_count'},
          //{ text: 'ReportUrl', align: 'start', sortable: true, value: 'report_url'},
          { text: '', align: 'end', value: 'actions', sortable: false},
          { text: '', align: 'end', value: '', width: '80px', sortable: false}
        ],
        errored: false,
        loading: false,
        alert: false,
        alert_type: 'warning',
        alert_message: '',
      }),
      
      watch: {
        alert(new_val){
          if(new_val){
            setTimeout(() => {this.alert = false},3000)
          }
        },
      },
      methods: {
        formatViewDate(value) {
          return moment(String(value)).format('YYYY-MM-DD HH:mm:ss');
        },
        formatViewExecutionTime(value) {
          return value/1000 + ' sec';
        },
        getStatusColor (status) {
            if (status === 'SUCCEEDED') return 'green'
            else if (status === 'FAILED') return 'red'
            else return 'gray'
        },
        downloadCSV(s3RawEventsLink) {
            if (!s3RawEventsLink) return

            const fileName = s3RawEventsLink.split('/').pop()

            const link = document.createElement('a')
            link.href = s3RawEventsLink
            link.download = fileName

            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)

            this.alert = false
        },
        copyToClipboard(text) {
            if (!text) return

            const textarea = document.createElement("textarea");
            textarea.value = text;
            document.body.appendChild(textarea);
            textarea.select();
            try {
                // TODO: document.execCommand is considered deprecated
                document.execCommand('copy');
                this.alert_type    = 'success'
                this.alert_message = 'Url copied to clipboard'
                this.alert         = true
            } 
            catch (err) {
                this.alert_type    = 'error'
                this.alert_message = 'Unable to copy token:', err
                this.alert         = true
            } 
        },
        replaceCameraIdsWithString(query) {
          let parts = query.split(',');
          let replacedParts = parts.map(part => {
            // if the part contains something like camera ID
            let cameraIdMatch = part.match(/'([a-zA-Z0-9-@]+)'/);
            if (cameraIdMatch) {
              let cameraId = cameraIdMatch[1];
              let camera   = this.cameras.find(cam => cam.camera_id === cameraId);
              if (camera) {
                return part.replace(cameraId, camera.camera_name);
              }
            }
            return part;
          });
    
          // join the parts together
          return replacedParts.join(',');
        }
      },
    }
  </script>
  <style>
    .v-alert {position:fixed!important;bottom: 35px!important;}
    .v-chip.status {
        border: none!important;
        font-size: 11px;
        height: 26px!important;
        padding-left: 0px!important;
    }
    .v-btn.download {
        margin: 4px 2px!important;
        font-size: 14px;
        height: 24px!important;
        padding: 2px 6px!important;
        min-width: 20px!important;
    }
    .v-btn.download button.v-icon.mdi {
        font-size: 16px!important;
    }
    .text-start {
      font-size: 11px!important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 300px;
    }
    .query {
      z-index: 9999;
      position: absolute;
      margin-top: -10px;
      max-width: 500px;
      white-space: pre-wrap;
      background-color: white;
      border: 1px solid grey;
      border-radius: 2px;
      color: grey;
      text-align: center;
      padding: 5px 2px;
    }
    .v-toolbar__title.text-h9 {
        padding-left: 10px!important;
        margin: 0px!important;
        border-radius: 0px!important;
        background-color: gray!important;
    }
  </style>