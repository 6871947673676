<template>
<div id="app">
<v-app id="insire">
    <v-card
      class="overflow-hidden"
      style="position: relative;"
      height="100%"
    >
      <NavDrawer></NavDrawer>    
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="100vh"
      >
          <router-view></router-view>
      </v-sheet>
    </v-card>
  </v-app>
</div>
</template>

<script>
import NavDrawer from "./components/NavDrawer.vue";
import i18n from "@/i18n"

export default {
  data: () => ({
  }),
  name: 'App',

  components: { 
    NavDrawer
  },
  created () {
    console.log("Active locale: ", i18n.locale)
    this.$vuetify.rtl = (i18n.locale === 'en' ? false : true)
  },
}

</script>
<style>
body {
  font-size: 14px!important;
}
</style>
