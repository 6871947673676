<template>
  <div class="list justify-center mt-8 pa-1">
    <v-text-field v-if="loading" color="success" loading disabled></v-text-field>

    <gmap-map v-else
        :zoom="options.zoom"    
        :center="options.center"
        style="width:100%;  height: 90vh;"
        v-bind="options"
        @click="onMapClick"
      >
        <gmap-cluster
          :gridSize="100"
          :zoomOnClick="true"
          :enableRetinaIcons="true"
          :minimumClusterSize="3"
          ref="cluster"
          @click="clusteredMarkers($event)"
        >
          <gmap-marker
            :key="index"
            v-for="(m, index) in locationMarkers"
            :position="m.position"
            :icon= "markerIcon(m.status_code)"
            @click="openMarker(m.id)"
          >
            <gmap-info-window
              :closeclick="true"
              @closeclick="openMarker(null)"
              :opened="openedMarkerID === m.id"
            >
              <CameraItem v-if="openedMarkerID === m.id" :item = locationMarkers[currCameraIndex]></CameraItem>
            </gmap-info-window>

          </gmap-marker>
        </gmap-cluster>
    </gmap-map>
  </div>
</template>
 
<script>
import API from './../api';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import CameraItem from '../components/CameraItem.vue';

export default {
  name: "Map",
  components: { GmapCluster, CameraItem },
  data() {
    return {
      options: {
        center: { lat: 32.09, lng: 34.85 },
        zoom: 12,
      },
      locationMarkers: [],
      loading: true,
      openedMarkerID: null,
      currCameraIndex: null
    };
  },
  mounted () {
    this.initialize()   
    //this.locateGeoLocation();
    window.addEventListener('gotLiveCamerasData', this.onCamerasLiveUpdatedEvent);
  },
  beforeRouteLeave(to, from, next)  {
    window.removeEventListener('gotLiveCamerasData', this.onCamerasLiveUpdatedEvent);
    next()
  },
  methods: {
    initialize () {
      API.getCameras()
      .then((response) => {
        if (response.data.isOk) {
          this.locationMarkers = response.data.data.map(camera => {
            return {...camera, 
              id: camera.camera_id, 
              status_code: API.appScope.getLiveCameraStatus(camera.camera_id).code,
              position: {"lat": camera.camera_lat, "lng": camera.camera_lon}
            }
          });
          this.loading = false
        }
      })
      .catch(error => {
        this.errored = !!error;
      })
      .finally(() => (this.loading = false));
    },
    onCamerasLiveUpdatedEvent() {
      this.locationMarkers.forEach(m => {
        m.status_code = API.appScope.getLiveCameraStatus(m.id).code
      });
    },
    markerIcon(status_code) {
      return {
        path : 'M16,3C10.5,3,6,7.5,6,13c0,8.4,9,15.5,9.4,15.8c0.2,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2C17,28.5,26,21.4,26,13 C26,7.5,21.5,3,16,3z M16,17c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S18.2,17,16,17z',
        fillColor: (status_code == '100' ? 'red' : (status_code == '0' ? 'green' : 'yellow')),
        fillOpacity: 1,
        strokeColor: '#555',
        strokeWeight: 1,
        scale: 1.2
      };
    },
    onMapClick(e) {
      console.log(e)
      this.openMarker(null)
    },
    openMarker(id) {
       this.openedMarkerID = id
       this.currCameraIndex = this.locationMarkers.findIndex(el => el.camera_id === id);
    },
    locateGeoLocation: function() {
      navigator.geolocation.getCurrentPosition(res => {
        this.options.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude
        };
      });
    },
    clusteredMarkers(event) {
      console.log(event.getMarkers());
    }
  }
};
</script>
<style>
.marker_info {
  padding: 5px;
  font-weight: 500;
}
.gm-style-iw.gm-style-iw-c {
  min-width: 330px!important;
  max-width: 550px!important;
}
</style>