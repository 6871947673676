<template>
  <v-dialog v-model="show" max-width="500px">
    <!--{{item}} -->
    <section v-if="errored">
      <Errored></Errored>
    </section>
    <section v-else>
      <v-card color="blue darken-2">
        <v-btn block icon small dark @click="show=false"> <v-icon small>mdi-close</v-icon> </v-btn>
        <v-text-field v-if="loading" color="success" loading disabled></v-text-field>
        <gmap-map v-else
          :zoom="options.zoom"    
          :center="options.center"
          style="width:100%;  height: 400px;"
          v-bind="options"
        >
          <gmap-marker :position="position"></gmap-marker>
            <!-- <gmap-marker :position="position" :icon="{ url: require('./../assets/blue_marker.png')}"></gmap-marker> -->
        </gmap-map>
      </v-card>
    </section>
  </v-dialog>
</template>
 
<script>
import Errored from './Errored.vue';

export default {
  name: "MapItem",
  components: { Errored },
  data() {
    return {
      loading: true,
      errored: false
    };
  },
  props: { 
    'visible' : Boolean,
    'item': Object
  },     
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    position: function () {
      return  ((this.item.camera_lat) && (this.item.camera_lon)) ? {lat: this.item.camera_lat, lng: this.item.camera_lon} :  {};
    },
    options: function () {
      return {
        center: this.position,
        zoom: 12,
        strokeColor:'#008000'
      }
    },
  },
  created () {
    this.loading = false
  },
}
</script>
<style scoped>
  .v-card.v-sheet.theme--light.blue.darken-2 {
    max-width: 500px;
    overflow: hidden;
  }
  .v-btn.v-btn--block { height: 40px; justify-content: flex-end;}
</style>