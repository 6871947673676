<template>
  <section v-if="errored">
    <Errored></Errored>
  </section>
  <section v-else>
    <div class="list justify-center mt-10 pa-1">
      <v-alert dense prominent :type="alert_type" :value="alert" transition="scale-transition" icon="mdi-map-marker-alert">
        {{ $t(alert_type === 'warning' ? WARN : (alert_type === 'success' ? OK : error)) }}
      </v-alert>

      <v-text-field v-if="loading" color="success" loading disabled></v-text-field>
  
      <v-data-table v-else
        :headers="headers"
        :items="users"
        :search="search"
        :footer-props="{
            itemsPerPageText: $t('Rows per page:'), 
            pageText: $t('{0}-{1} of {2}'),
            'items-per-page-options':[15, 30, -1]
          }"
        sort-by="id"
        class="elevation-1 grey lighten-2 row-pointer"
        mobile-breakpoint="0"
        item-key="id"
        single-select
        @click:row="rowClick"
      >
        <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
          {{ $t(header.text) }}
        </template>
    
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>         
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              v-bind:label="$t('Search')"
              single-line
              clearable
              class="pa-1 pt-4 shrink"
            ></v-text-field>
    
            <v-dialog v-model="dialog" persistent hide-overlay max-width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="isEditAllow"
                  color="light-blue darken-2" dark small class="ma-1"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="false"
                >
                {{ $t("New User") }}
                </v-btn>
              </template>
              <v-card color="grey lighten-3" class="user_item" height="100%">
                <v-toolbar dense dark>
                  <v-toolbar-title>{{ $t(formTitle) }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon small dark @click="dialog = false"> <v-icon small>mdi-close</v-icon> </v-btn>
                </v-toolbar>

                <v-form ref="form" lazy-validation :readonly="!isNewItem && isReadOnly">                
                  <v-container>
                    <v-row>
                      <v-col cols="8" sm="8" md="8" v-if="!isNewItem">
                        <v-text-field
                          v-model="editedItem.id"
                          v-bind:label="$t('Id')"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" sm="2" md="2"  v-if="!isNewItem && isEditAllow">
                        <v-btn class="ma-0 pa-0 tooltip" color="red darken-1" dark :disabled="isReadOnly" @click="deleteItem(editedItem)">
                          <span class="tooltiptext">{{ $t('Delete') }}</span>
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="2" sm="2" md="2" v-if="!isNewItem && isEditAllow">
                        <v-btn class="ma-0 pa-0 tooltip" color="blue darken-1" dark @click="isReadOnly = !isReadOnly">
                          <span class="tooltiptext">{{ $t('Edit') }}</span>
                          <v-icon>mdi-file-document-edit-outline</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.user"
                          v-bind:label="$t('Login')"
                          :readonly="!isNewItem"
                          :rules="[rules.required]"
                        >
                          <template #message="{ message }">{{ $t(message) }}</template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-if="isNewItem">
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.password"
                          v-bind:label="$t('Password')"
                          :rules="[rules.required]"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-select
                          dense
                          :items="roles"
                          v-model="editedItem.role"
                          v-bind:label="$t('Role')"
                          :rules="[rules.required]"
                          :disabled="!isNewItem && isReadOnly"
                        >   
                          <template #message="{ message }">{{ $t(message) }}</template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-container> 
                
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success"        v-if="isEditAllow" @click="save" :disabled="!isNewItem && isReadOnly"> {{ $t("Save") }} </v-btn>
                    <v-btn color="grey lighten-1" v-if="isEditAllow" @click="close"> {{ $t("Cancel") }} </v-btn>
                    <v-btn color="grey lighten-1" v-else @click="close"> {{ $t("Close") }} </v-btn>
                  </v-card-actions>
                </v-form>
                <v-form ref="resetForm" lazy-validation v-if="!isNewItem && isEditAllow">                
                <v-row>
                  <v-col cols="3" sm="3" md="3" class="col_reset">
                    <v-checkbox 
                      v-model="show_reset" 
                      v-bind:label="$t('Reset password')"
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" v-if="show_reset">
                    <v-text-field color="success"
                      v-model="password"
                      :rules="[rules.required]"
                      v-bind:label="$t('Password')"
                      class="shrink"
                      type="password"
                      prepend-icon="mdi-lock-outline"
                      @input = "error = false"
                      required
                    >
                      <template #message="{ message }">{{ $t(message) }}</template>
                    </v-text-field>
                    <v-text-field color="success"
                      v-model="confirmPassword"
                      :rules="[rules.required, checkConfirmPassword]"
                      v-bind:label="$t('Confirm password')"
                      class="shrink"
                      type="password"
                      prepend-icon="mdi-lock-outline"
                      @input = "error = false"
                      required
                    >
                      <template #message="{ message }">{{ $t(message) }}</template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3" md="3" v-if="show_reset">
                    <v-btn color="success" v-if="isEditAllow" @click="reset"> {{ $t("Reset") }} </v-btn>
                  </v-col>
                  <v-alert dense prominent :type="alert_type" :value="alert_reset" transition="scale-transition" icon="mdi-map-marker-alert">
                    {{ $t(alert_type === 'warning' ? WARN_RESET : (alert_type === 'success' ? OK_RESET : error)) }}
                  </v-alert>
                </v-row>
            </v-form>

              </v-card>
            </v-dialog>   
            <dialogDelete :visible="dialogDelete" @close="dialogDelete=false" @confirm="deleteItemConfirm"></dialogDelete>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">       
          <v-icon class="mr-2 good" :title="$t('Admin')" v-if="item.role === 9">mdi-security</v-icon>       
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize"> {{ $t("Reset") }} </v-btn>
        </template>
      </v-data-table>
    </div>
  </section>
</template>

<script>
import dialogDelete from './DialogDelete.vue';
import Errored from './Errored.vue';
import API from './../api';

export default {
  name: 'UserList',
  components: { dialogDelete, Errored },
  data: () => ({
    OK: "User deleted successfully",
    OK_RESET: "Password changed successfully",
    WARN: "Cannot delete user",
    WARN_RESET: "Can't change password",
    dialog: false,
    dialogDelete: false,
    search: '',
    rules: { required: v => !!v  || 'Item is required' },
    headers: [
      { text: 'Id',  align: 'start', value: 'id', filterable: false },
      { text: 'User', align: 'start', value: 'user' },
//      { text: 'Role', align: 'start' , value: 'role' },
      { text: '', align: 'end', class: "pa-0", value: 'actions', sortable: false, filterable: false },
    ],
    users: [],
    roles: [
      { text: 'user', value: 1 },
      { text: 'admin', value: 9 }
    ],
    isReadOnly: true,
    editedIndex: -1,
    editedItem: {
      user: '',
      role: '',
      password: '',
    },
    defaultItem: {
      user: '',
      role: '',
      password: '',
    },
    show_reset: false,
    password: '',
    confirmPassword: '',
    loading: true,
    alert: false,
    alert_reset: false,
    alert_type: 'warning',
    error: '',
    errored: false
  }),

  computed: {
    isEditAllow () { 
      return (API.appScope.theCurrUserRole === API.appConst.ROLE_ADMIN) 
    },
    formTitle () {
      return this.editedIndex === -1 ? 'New User' : 'Edit User'
    },
    isNewItem () {
      return this.editedIndex === -1 ? true : false
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    alert(new_val){
      if(new_val){
        setTimeout(() => {this.alert = false},5000)
      }
    },
    alert_reset(new_val){
      if(new_val){
        setTimeout(() => {this.alert_reset = false},3000)
      }
    },
    show_reset(val){
      if (val === false) {
        if (this.$refs.resetForm)
        {
          this.clearFormData(this.$refs.resetForm)
        }
      }
    }
  },

  mounted () {
    this.initialize()
  },

  methods: {   
    initialize () {
      API.getUsers()
      .then((response) => {
        if (response.data.isOk) {
          this.users = response.data.data.map(user => {
            return { id: user.id, user: user.user, role: user.role }
          });
        }
      })
      .catch(error => {
        this.errored = !!error;
      })
      .finally(() => (this.loading = false));
    },

    editItem (item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.users.findIndex(el => el.id === item.id);
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      API.deleteUser(this.editedItem.id)
        .then((response) => {
          if (response.data.isOk) {
            this.users.splice(this.editedIndex, 1)
            this.alert_type = 'success';
            this.alert = true
          }
          else {
            this.alert_type = 'error';
            this.error  = response.data.error.message
            this.alert  = true
          }
        })
        .catch(error => {
          this.errored = !!error;
        })
        .finally(() => {
          this.loading = false
          this.closeDelete()
          this.close()
        })
    },

    close () {
      this.dialog = false
      
      this.editedItem  = Object.assign({}, this.defaultItem)
      this.editedIndex = -1   
      this.isReadOnly  = true
      this.show_reset  = false

      this.$refs.form.resetValidation()
      if (this.$refs.resetForm) {
        this.clearFormData(this.$refs.resetForm)
      }
    },

    closeDelete () {
      this.dialogDelete = false
    },

    save () {
      const isValid = this.$refs.form.validate();
      if (!isValid) return

      if (this.editedIndex > -1) {
        // edit User
        API.editUser(this.editedItem)
        .then((response) => {
          if (response.data.isOk) {
            Object.assign(this.users[this.editedIndex], this.editedItem)
          }
          else {
            this.alert_type = 'error';
            this.error  = 'Error: ' + response.data.error.message
            this.alert  = true
          }
        })
        .catch(error => {
          this.errored = !!error;
        })
        .finally(() => {
          this.loading = false
          this.close()
        })
      }
      else {
        // new User
        API.addUser(this.editedItem)
        .then((response) => {
          if (response.data.isOk) {
            this.editedItem.id = response.data.data
            this.users.push(this.editedItem)
          }
          else {
            this.alert_type = 'error';
            this.error  = 'Error: ' + response.data.error.message
            this.alert  = true
          }
        })
        .catch(error => {
          this.errored = !!error;
        })
        .finally(() => {
          this.loading = false
          this.close()
        })
      }
    },
    reset () {
      const isValid = this.$refs.resetForm.validate();
      if (!isValid) return

      if (this.editedIndex > -1) {
        // exists User
        API.resetPassword({user: this.editedItem.user, password: this.password})
        .then((response) => {
          if (response.data.isOk) {
            this.users[this.editedIndex].password = this.editedItem.password
            this.show_reset  = false
            this.alert_type  = 'success';
            this.alert_reset = true
            this.clearFormData(this.$refs.resetForm)
          }
          else {
            this.alert_type  = 'error';
            this.error       = response.data.error.message
            this.alert_reset = true
          }
        })
        .catch(error => {
          this.alert_type = 'warning';
          this.alert_reset = true
          this.WARN_RESET = error.response.statusText
        })
        .finally(() => {
          this.loading = false
        })
      }
    },
    rowClick (item, row) {      
      row.select(true);
      this.selectedId = item.point_id
      this.editItem(item)
    },
    checkConfirmPassword(v) {
      return v == this.password || this.$t('The password confirmation does not match')
    },
    clearFormData (form) {
      form.reset();
      form.resetValidation();
    }
  },
}
</script>
<style>
  .user_item .v-text-field { padding-top: 0;}
  .user_item .v-text-field__details { padding-bottom: 2px;}
  .user_item .v-input--selection-controls {padding-top: 0; margin-top: 0;}
  .user_item .v-input--selection-controls .v-input__slot > .v-label {
    font-size: 12px;
    color: rgba(0, 0, 0, 1);
  }
  .user_item .col_reset {
    padding-top: 0;
    padding-right: 0;
    max-width: 20%;
  }
  .user_item .v-text-field > .v-input__control > .v-input__slot { margin-bottom: 2px;}
  .user_item .theme--dark.v-toolbar.v-sheet { height: 42px!important;}
  .user_item .v-toolbar__content { height: 42px!important;}
</style>