import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify'
import i18n from './i18n'
import router from "./router/router"
import * as VueGoogleMaps from "vue2-google-maps"
import './App.css';

import axios from 'axios'

Vue.prototype.axios = axios

Vue.use(Vuetify);
Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAsmqb_Xn0ytEJ1IpTikQtO2_c_C6GQnSM",
    libraries: "places"
  }
});

new Vue({
  vuetify: new Vuetify,
  render: h => h(App),
  i18n,
  router
}).$mount('#app')