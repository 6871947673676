<template>
  <v-card class="settings elevation-2 outlined mx-auto my-12" 
          max-width="350"
          color="grey lighten-3"
          >
    <v-toolbar dark>
      <v-toolbar-title>{{ $t("Change password") }}</v-toolbar-title>
    </v-toolbar>
    <v-alert dense prominent :type="alert_type" :value="alert" transition="scale-transition" icon="mdi-map-marker-alert">
        {{ $t(alert_type === 'warning' ? WARN : (alert_type === 'success' ? OK : error)) }}
    </v-alert>
     <v-card-text>
        <v-form ref="passwordForm"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-text-field color="success"
            v-model="oldPassword"
            :rules="[v => !!v || 'Item is required']"
            v-bind:label="$t('Old password')"
            class="shrink"
            prepend-icon="mdi-lock"
            :append-icon="show_pass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show_pass ? 'text' : 'password'"
            @click:append="show_pass = !show_pass"
            @input = "error = false"
            required
          >

            <template #message="{ message }">{{ $t(message) }}</template>
          </v-text-field>
          <v-text-field color="success"
            v-model="newPassword"
            :rules="[v => !!v || this.$t('Item is required')]"
            v-bind:label="$t('New password')"
            class="shrink"
            prepend-icon="mdi-lock-outline"
            type="password"
            @input = "error = false"
            required
          >
            <template #message="{ message }">{{ $t(message) }}</template>
          </v-text-field>
          <v-text-field color="success"
            v-model="confirmPassword"
            :rules="[v => !!v || this.$t('Item is required'), newPassword === confirmPassword || this.$t('The password confirmation does not match')]"
            v-bind:label="$t('Confirm new password')"
            class="shrink"
            prepend-icon="mdi-lock-outline"
            type="password"
            @input = "error = false"
            required
          >
            <template #message="{ message }">{{ $t(message) }}</template>
          </v-text-field>
          
          <div v-if="error" class="auth-error error--text">
            {{ $t(error) }}
          </div>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-btn type='submit'  color="success">{{ $t("Save") }}</v-btn>
            <v-btn @click="close" color="grey lighten-1">{{ $t("Cancel") }}</v-btn>
          </v-card-actions>
        </v-form>
     </v-card-text>
  </v-card>
</template>


<script>
import API from './../api';

export default {
  name: "UserSettings",
  data() {
    return {
      OK: "Password changed successfully",
      WARN: "Error: invalid old password",
      user: API.appScope.theCurrUserName,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      show_pass: false,
      alert: false,
      alert_type: 'warning',
      error: false,
      valid: false,
    };
  },
  watch: {
    alert(new_val){
      if(new_val){
        setTimeout(() => {this.alert = false},3000)
      }
    }
  },
  methods: {
    save () {
      const { user, oldPassword, newPassword } = this

      const isValid = this.$refs.passwordForm.validate();
      if (!isValid) return

      API.changePassword({ user, oldPassword, newPassword })
      .then(response => {
        if (response.data.isOk) {
          this.error = false
          this.alert_type = 'success';
          this.alert = true
          this.resetForm();
        }
        else {
          this.alert_type = 'warning';
          this.alert = true
          this.error = response.data.error.message + this.$t(': Please double-check and try again')
        }
      })
      .catch(error => {
        this.alert_type = 'warning';
        this.alert = true
        this.error = error.response.statusText + this.$t(': Please double-check and try again')
      })
    },
    close () {
      this.$router.push('/cameras')
    },
    resetForm () {
      this.$refs.passwordForm.reset()
      this.$refs.passwordForm.resetValidation()
    },
  }
};
</script>

<style scoped>
.auth-error {
  margin-top: -20px;
  padding-left: 33px;
  font-size: 12px;
  letter-spacing: 0;
}
::v-deep .v-text-field .theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
}
::v-deep .theme--light.v-input input {
  color: rgba(0, 0, 0, 0.6);
}
</style>
