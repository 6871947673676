import axios from 'axios'
import router from "./router/router"
import { HubConnectionBuilder, HttpTransportType, LogLevel } from '@microsoft/signalr';
const monitoringHubConnection = new HubConnectionBuilder()
      .withUrl("MonitoringEvent", {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => {
          return appScope.getToken();                                         
        }
      })
      .configureLogging(LogLevel.Information)
      .build();

const LIVE_CAMERAS_UPDATE_TIME = 10000;

var theLiveCamerasDataTimerId  = null;
var eventIntervarID;

const HTTP = axios.create({
  baseURL: '/api/configuration',
  withCredentials: true
})
const HTTP_STAT = axios.create({
  baseURL: '/api/reports',
  withCredentials: true
})
const HTTP_AUTH = axios.create({
  baseURL: '/api/Identity',
  withCredentials: true
})

const HTTP_MONITORING = axios.create({
  baseURL: '/api/monitoring',
  withCredentials: true
})

function parseJwt (token) {
  try {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  } 
  catch {
    return null
  }
}

var appConst =
{
  ROLE_ADMIN : 'Admin',
  TOKEN_NAME : 'accessToken',
  TOKEN_REFRESH_NAME : 'refreshToken',
  TOKEN_EXPIRY : 300000, // 5 min

  LIVE_STATUS_UNKNOWN : {'type':  null,  'code': null, 'message' : 'Status unknown'},
  LIVE_STATUS_GOOD    : {'type': 'good', 'code': 0,    'message' : 'Online'},
  LIVE_STATUS_FAIL    : {'type': 'fail', 'code': 100,  'message' : 'Offline'},
  LIVE_STATUS_EVENT_TIMEOUT  : {'type': 'warn', 'code': 10, 'message' : 'Event Timeout'},
  LIVE_STATUS_LOW_CONFIDENCE : {'type': 'warn', 'code': 11, 'message' : 'Low Confidence'},
};

var appScope = new class AppScope
{ 
  // Token cache functions
  theToken        = null;
  theRefreshToken = null;
  theCurrUserName = null;
  theCurrUserRole = null;

  refreshTokenTimeoutId = null;

  fillByToken(newToken)
  {
    if (newToken == null) {
      this.theCurrUserName = null;
      this.theCurrUserRole = null;
    } 
    else {
      let payload = parseJwt(newToken); 
      if (!payload) {
        this.theCurrUserName = null;
        this.theCurrUserRole = null;
        }
      else {
        this.theCurrUserName  = payload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
        this.theCurrUserRole = payload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      }
    }
  }

  constructor()
  {
    this.theToken = window.localStorage.getItem(appConst.TOKEN_NAME);
    this.theRefreshToken = window.localStorage.getItem(appConst.TOKEN_REFRESH_NAME);
    this.fillByToken(this.theToken);
  }

  setToken(newToken)
  {
    this.theToken = newToken;
    this.fillByToken(this.theToken);
  }

  getToken()
  {
    return this.theToken;
  }

  setRefreshToken(newRefreshToken)
  {
    this.theRefreshToken = newRefreshToken;
  }

  getRefreshToken()
  {
    return this.theRefreshToken;
  }

  refreshToken() {
    HTTP_AUTH.post('/refresh_token', {
      'accessToken': appScope.getToken(),
      'refreshToken': appScope.getRefreshToken()
    })
    .then((r) => { 
      const newAccessToken  = r.data.accessToken;
      const newRefreshToken = r.data.refreshToken;

      window.localStorage.setItem(appConst.TOKEN_NAME, newAccessToken);
      window.localStorage.setItem(appConst.TOKEN_REFRESH_NAME, newRefreshToken);
 
      this.setToken(newAccessToken);
      this.setRefreshToken(newRefreshToken);

      clearTimeout(this.refreshTokenTimeoutId);

      this.scheduleTokenRefresh();
    })
    .catch((error) => {
      console.log('refreshToken:', error)
    })
  }
  
  scheduleTokenRefresh() {
    this.refreshTokenTimeoutId = setTimeout(() => {
      this.refreshToken();
    }, appConst.TOKEN_EXPIRY);
  }

  // Login/logout

  doLogin(newToken, newRefreshToken)
  {
    window.localStorage.setItem(appConst.TOKEN_NAME, newToken);
    window.localStorage.setItem(appConst.TOKEN_REFRESH_NAME, newRefreshToken);

    // Schedule the next token refresh
    this.scheduleTokenRefresh();

    this.setToken(newToken);
    this.setRefreshToken(newRefreshToken);

    window.dispatchEvent(new CustomEvent('isLogin', {
      detail: {
        login: true
      }
    }));
    startMonitoringHubConnection();
    startLiveCamerasData();   
  }

  doLogout()
  {
    if (!appScope.isLoggedIn()) return;

    console.log('Logout');
    clearTimeout(this.refreshTokenTimeoutId);

    window.localStorage.removeItem(appConst.TOKEN_NAME);
    window.localStorage.removeItem(appConst.TOKEN_REFRESH_NAME);
    this.setToken(null);
    this.setRefreshToken(null);

    window.dispatchEvent(new CustomEvent('isLogin', {
      detail: {
        login: false
      }
    }));

    stopLiveCamerasData();
    stopMonitoringHubConnection();

    clearInterval(eventIntervarID);
    router.push('/').catch(error => {
      // on abort only ignore NavigationDuplicated error
      if (error.name !== 'NavigationDuplicated') {
        throw(error); 
      }
    })
  }

  isLoggedIn()
  {
    return this.theToken != null;
  }

  splitCoordinates(location) {
    if (!location) return { lat: 0, lng: 0 }
    const [lat, lng] = location.split(',').map(parseFloat);
    if (!lat || !lng) return { lat: 0, lng: 0 }
    return { lat: parseFloat(lat.toFixed(4)), lng: parseFloat(lng.toFixed(4)) };
  }
  
  joinCoordinates(lat, lon) {
    return `${lat},${lon}`;
  }

};

HTTP_STAT.interceptors.request.use(
  (config) => {
    if (appScope.isLoggedIn()) { config.headers['Authorization'] = `Bearer ${appScope.getToken()}`; }
    return config;
  }, 
  (error) => { return Promise.reject(error); }
);


HTTP_MONITORING.interceptors.request.use(
  (config) => {
    if (appScope.isLoggedIn()) { config.headers['Authorization'] = `Bearer ${appScope.getToken()}`; }
    return config;
  }, 
  (error) => { return Promise.reject(error); }
);

HTTP.interceptors.request.use(
  (config) => {
    if (appScope.isLoggedIn()) { config.headers['Authorization'] = `Bearer ${appScope.getToken()}`; }
    return config;
  }, 
  (error) => { return Promise.reject(error); }
);

HTTP_AUTH.interceptors.request.use(
  (config) => {
    if (appScope.isLoggedIn()) { config.headers['Authorization'] = `Bearer ${appScope.getToken()}`; }
    return config;
  }, 
  (error) => { return Promise.reject(error); }
);


HTTP_AUTH.interceptors.response.use(
  (response) => { return response; },
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry && originalRequest.url != '/logout') {
        appScope.doLogout()
    }
    return Promise.reject(error);
  }
);

HTTP.interceptors.response.use(
  (response) => { return response; },
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshResponse = await HTTP_AUTH.post('/refresh_token', {
          'refreshToken': appScope.getRefreshToken(),
          'accessToken': appScope.getToken()
        })
        const newAccessToken  = refreshResponse.data.accessToken;
        const newRefreshToken = refreshResponse.data.refreshToken;

        appScope.doLogin(newAccessToken, newRefreshToken);

        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`
        return await axios(originalRequest)
      } 
      catch (refreshError) {
        console.error('Error refreshing token ', refreshError.code)
        appScope.doLogout()
        return await Promise.reject(refreshError)
      }
    }
    return Promise.reject(error);
  }
);

HTTP_STAT.interceptors.response.use(
  (response) => { return response; },
  async (error) => {
    const originalRequest = error.config;
  
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
  
      try {
        const refreshResponse = await HTTP_AUTH.post('/refresh_token', {
          'refreshToken': appScope.getRefreshToken(),
          'accessToken': appScope.getToken()
        })
        const newAccessToken  = refreshResponse.data.accessToken;
        const newRefreshToken = refreshResponse.data.refreshToken;
  
        appScope.doLogin(newAccessToken, newRefreshToken);
  
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`
        return await axios(originalRequest)
      } 
      catch (refreshError) {
        console.error('Error refreshing token ', refreshError.code)
        appScope.doLogout()
        return await Promise.reject(refreshError)
      }
    }
    return Promise.reject(error);
  }
);
  
HTTP_MONITORING.interceptors.response.use(
  (response) => { return response; },
  async (error) => {
    const originalRequest = error.config;
  
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
  
      try {
        const refreshResponse = await HTTP_AUTH.post('/refresh_token', {
          'refreshToken': appScope.getRefreshToken(),
          'accessToken': appScope.getToken()
        })
        const newAccessToken  = refreshResponse.data.accessToken;
        const newRefreshToken = refreshResponse.data.refreshToken;
  
        appScope.doLogin(newAccessToken, newRefreshToken);
  
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`
        return await axios(originalRequest)
      } 
      catch (refreshError) {
        console.error('Error refreshing token ', refreshError.code)
        appScope.doLogout()
        return await Promise.reject(refreshError)
      }
    }
    return Promise.reject(error);
  }
);

//====================================== LIVE CAMERAS START ===========================================================

appScope.theCamerasData  = null;

function reqLiveCamerasData() {
  if (!appScope.isLoggedIn()) {return;}

  HTTP_MONITORING.get('/cameras').then((response) =>
  {
    appScope.theCamerasData = response.data.data;
    theLiveCamerasDataTimerId = setTimeout(reqLiveCamerasData, LIVE_CAMERAS_UPDATE_TIME);
    //console.log('INFO', 'reqLiveCamerasData', response.data.data);
    window.dispatchEvent(new CustomEvent('gotLiveCamerasData', {
      detail : { newCamerasData: response.data.data }
    }));          
  }).catch((error) =>
  {
    console.log('ERROR!', 'reqLiveCamerasData', error);
  });
}

appScope.getLiveCamerasData = ()  => { return appScope.theCamerasData; }

appScope.getLiveCameraStatus = (camera_id) => {
 
  if (camera_id == null) { return appConst.LIVE_STATUS_UNKNOWN; }
  if (appScope.theCamerasData == null) { return appConst.LIVE_STATUS_UNKNOWN; }
  var item = appScope.theCamerasData.find(el => el.camera_id === camera_id);
  if (item == null) { return appConst.LIVE_STATUS_UNKNOWN; }

  if (item.camera_status == 0)   { return appConst.LIVE_STATUS_GOOD; }
  if (item.camera_status == 100) { return appConst.LIVE_STATUS_FAIL; }
  if (item.camera_status == 10)  { return appConst.LIVE_STATUS_EVENT_TIMEOUT; }
  if (item.camera_status == 11)  { return appConst.LIVE_STATUS_LOW_CONFIDENCE; } 
}

appScope.getLivePointStatus = (point_id) => {
  if (point_id == null) { return appConst.LIVE_STATUS_UNKNOWN; }
  if (appScope.theCamerasData == null) { return appConst.LIVE_STATUS_UNKNOWN; }

  var item = appScope.theCamerasData.filter(el => el.point_id === point_id);
  if (item == null || item.length === 0) { return appConst.LIVE_STATUS_UNKNOWN; }

  // because can be several cameras on one point - 
  // we determine the point status by by worst camera status
  for (const i of item) {
    if (i.camera_status == 100) { return appConst.LIVE_STATUS_FAIL; }
  }
  for (const i of item) {
    if (i.camera_status == 10)  { return appConst.LIVE_STATUS_EVENT_TIMEOUT; }
    if (i.camera_status == 11)  { return appConst.LIVE_STATUS_LOW_CONFIDENCE; } 
  }
  return appConst.LIVE_STATUS_GOOD;
}

var startLiveCamerasData = () => {
  if (theLiveCamerasDataTimerId == null) { reqLiveCamerasData(); }
}

var stopLiveCamerasData = () => { 
  if (theLiveCamerasDataTimerId != null) { clearTimeout(theLiveCamerasDataTimerId); theLiveCamerasDataTimerId = null; }
}

var startMonitoringHubConnection = () => {
  monitoringHubConnection.on("onConnect", function (message) {
    console.log('monitoringHubConnection started:', message)
  });

  monitoringHubConnection.on("onCameraStatusChangedEventReceive", function (message) {
    console.log('CameraStatusChangedEventReceive:',JSON.parse(message));
    /*
    window.dispatchEvent(new CustomEvent('gotLiveCamerasData', {
      detail : { newCamerasData: JSON.parse(message) }
    }));          
    */
  });

  monitoringHubConnection.on("onPointStatusChangedEventReceive", function (message) {
    console.log('PointStatusChangedEventReceive:',JSON.parse(message));
  });

  monitoringHubConnection.start().catch(function(err) {return console.error(err.toString());});
}

var stopMonitoringHubConnection = () => {
  monitoringHubConnection.stop()
      .then(function() {console.log('monitoringHubConnection: closed');})
      .catch(function(err) {return console.error(err.toString());})
}

// first start when loading the application
if (appScope.isLoggedIn()) { 
  startLiveCamerasData(); 
  startMonitoringHubConnection(); 
}

window.appScope = appScope;

//====================================== LIVE CAMERAS END ===========================================================
//
//
//
//

export default {
  appConst,
  appScope,

  // --------------- Camera
  // obj => {
  //  "camera_id": "string",
  //  "point_id": 0,
  //  "controller_id": 0,
  //  "lane_num": 0,
  //  "camera_lat": 0,
  //  "camera_lon": 0,
  //  "camera_name": "string",
  //  "camera_desc": "string"
  // }

  getCamerasCount () {
    return HTTP.get('/cameras/count')
  },
  getCameras () {
    return HTTP.get('/cameras')
  },
  getCamera (id) {
    return HTTP.get('/cameras/' + id)
  },
  addCamera (obj) {
    return HTTP.post('/cameras', obj)
  },
  editCamera (obj) {
    return HTTP.put('/cameras', obj)
  },
  deleteCamera (id) {
    return HTTP.delete('/cameras/' + id)
  },

  // --------------- Reports
  // str : IdsStr (array of strings '1','2','3'...), fromFormattedStatTime (string), toFormattedStatTime (string)
  makeRawEventsData (str) {
    return HTTP_STAT.get('/rawevents' + str)
  },
  makeAthenaReportQueryList () {
    return HTTP_STAT.get('/athena_report_query_list')
  },
  
  // --------------- Identity
  //  obj => { "id":0, "user":"string", "password":"string", "role":1 }
  //
  login (obj) {
    // obj => { "user":"string", "password":"string" }
    return HTTP_AUTH.post('/login', obj)
  },
  getUsers () {
    return HTTP_AUTH.get('/users')
  },
  addUser (obj) {
    return HTTP_AUTH.post('/users', obj)
  },
  editUser (obj) {
    return HTTP_AUTH.put('/users', obj)
  },
  getUserById (id) {
    return HTTP_AUTH.get('/users/' + id)
  },
  deleteUser (id) {
    return HTTP_AUTH.delete('/users/' + id)
  },
  changePassword (obj) {
    return HTTP_AUTH.post('/change_password', obj)
  },
  resetPassword (obj) {
    return HTTP_AUTH.post('/reset_password', obj)
  },
 
}