<template>
 <section>
  <v-system-bar color="gray" height="20"></v-system-bar>
  <v-app-bar 
        absolute
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
        color="gray" dark dense >
    <v-img
      contain
      max-height="50"
      max-width="150"
      src="./../assets/logo/logo-transparent-png.png"
      @mouseover="buildInfo = true"
      @mouseout="buildInfo = false"
    ></v-img>
    <span class="caption" v-show="buildInfo">{{ buildBranch }} {{ buildNumber }}</span>
    <v-app-bar-nav-icon class="d-block d-sm-none order-2" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
  
    <router-link class="router-link d-none d-sm-block"
      v-for="item in items"
        :key="item.title"
        :to="item.route"
      >
        <v-col class="fill-height col-sm" :class=item.title>
          <v-icon>{{ item.icon }}</v-icon>
          {{ $t(item.title) }}
        </v-col>
    </router-link >
    <v-btn id="user_settings" class="tooltip" icon color="white" @click="$router.push('/user_settings').catch(error => {})">
      <span class="tooltiptext">{{ $t('Change password') }}</span>
      <v-icon>mdi-account-edit</v-icon>
    </v-btn>
    <Lang></Lang>
  </v-app-bar>
  <v-navigation-drawer
    v-model="drawer"
    absolute
    :right ="$i18n.locale === 'en' ? false : true"
    temporary
    width="220px"
  >
    <v-list nav dense>
      <v-list-item-group
        v-model="group"
        active-class="gray"
      >
        <v-list-item 
          v-for="item in items"
            :key="item.title"
            :to="item.route"
            link
          >
            <v-list-item-content><v-list-item-title>{{ $t(item.title) }}</v-list-item-title></v-list-item-content>
            <v-list-item-icon><v-icon>{{ item.icon }}</v-icon></v-list-item-icon>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
 </section>
</template>

<script>
import Lang from './MenuLang.vue';
import API from './../api';

export default {
    name: "NavDrawer",
    components: {  Lang },
    data() {
        return {
            drawer: false,
            group: null,
            items: [
              { title: "Map", icon: "mdi-map-marker-multiple-outline", route: "/map" },
              { title: "Cameras", icon: "mdi-camera-outline", route: "/cameras" },
              { title: "Report", icon: "mdi-chart-line", route: "/report" },
              { title: "Users", icon: "mdi-account-search", route: "/users" },
              { title: "Logout", icon: "mdi-logout", route: "/logout", class:"logout" },
            ],
            mini: true,
            buildInfo: false,
            buildBranch: null,
            buildNumber: null,
        };
    },   
    mounted() {
      console.log('ND:', API); 
      let btn = document.getElementById('user_settings')

      API.appScope.isLoggedIn() ? btn.style.display = 'block' : btn.style.display = 'none'
      window.addEventListener('isLogin', (event) => {
        event.detail.login === true ? btn.style.display = 'block' : btn.style.display = 'none'
      });

      const file = "/version.js"; 
      fetch(file)
      .then((response) => response.text())
      .then((data) => {
        const lines = data.split('\n')

        for (const line of lines) {
          if (line.includes('buildInfo.branch')) {
            this.buildBranch = line.split(':')[1].trim().replace(/'/g, '')
          }
          else if (line.includes('buildInfo.buildNumber')) {
            this.buildNumber = line.split(':')[1].trim().replace(/'/g, '')
          }
        }
      })
      .catch((error) => {
        console.error("Error reading the file:", error)
      })

    },
    props: { },
    methods: { 
    },
    watch: {
        group() {
            this.drawer = false;
        },
        $route (to) {
          console.log('WATCH: Go to:',to.name)
          if (to.name === 'Logout') {
            API.appScope.doLogout();
            if (this.$route.path !== '/') this.$router.push('/').catch();
          }
        }
    },
 }
</script>

<style>
#user_settings {display: none;}
.v-navigation-drawer {
  top: 55px!important;
}
.v-navigation-drawer__content {
    width: 100%!important;
}
.router-link {
  width: 70px;
  height: 58px;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color:aliceblue!important;
  font-family:Roboto, sans-serif!important;
  letter-spacing: .09em!important;  
}
a.router-link:hover {
  color: #7bf77f!important;
}
a.router-link:hover .Logout {
  color:coral!important;
}
a.router-link:hover .theme--dark.v-icon{
  color:#4caf50;
}
a.router-link:hover .Logout .theme--dark.v-icon {
  color:coral;
}
.router-link-exact-active.router-link-active {
  background-color: gray;
}

.v-list-item-group a:last-of-type {
  border-top: 1px solid gray;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.v-toolbar__content a:last-of-type {
  border-left: 1px solid gray;
}

.Logout, .Logout .v-icon {color: #f8a384;}
.col {
    padding: 10px!important;
    display: grid;
}

.v-card .caption {
  position: relative;
  top: 8px;
  font-size: 10px !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: 300;
}
.v-card .caption a { color:white; text-decoration: none;}

</style>
